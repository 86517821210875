import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import PostSnippetCardLandingPages from "../components/PostSnippetCardLandingPages";
import ThreeLinkBlock from "../components/ThreeLinkBlock";

import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const TestimonyPageTemplate = ({ heroImage, intro, posts }) => {
  return (
    <>
      <Row className="py-5 laas-half-bg">
        <Col className="col-12 col-sm-10 offset-sm-1">
          <div style={{ position: "relative" }}>
            <PreviewCompatibleImage
              imageInfo={heroImage}
              style={{ maxHeight: "550px" }}
            />

            <div className="hero-tag">
              <div>
                <i className="text-white fas fa-caret-down"></i>
              </div>
              <h2 className="text-white font-cb text-uppercase">Testimony</h2>
            </div>
          </div>
        </Col>
        <Col className="col-12 col-sm-8 offset-sm-2 pt-4">
          <p className="text-line-height font-cr text-white">{intro}</p>
        </Col>
      </Row>

      <Row className="pt-4">
        {posts.map(post => (
          <Col
            className="col-10 offset-1 col-sm-5 offset-sm-1 mx-auto pb-5"
            key={post.id}
            style={{ maxWidth: "400px" }}
          >
            <PostSnippetCardLandingPages
              title={post.frontmatter.title}
              headline={post.frontmatter.headline}
              excerpt={post.excerpt}
              slug={post.fields.slug}
              image={post.frontmatter.image}
            />
          </Col>
        ))}
      </Row>
      <ThreeLinkBlock />
    </>
  );
};

const TestimonyPage = ({ data }) => {
  return (
    <Layout>
      <TestimonyPageTemplate
        heroImage={data.page.frontmatter.heroImage}
        intro={data.page.frontmatter.intro}
        posts={data.posts.nodes}
      />
    </Layout>
  );
};

export default TestimonyPage;

export const pageQuery = graphql`
  query TestimonyPageTemplate {
    page: markdownRemark(
      frontmatter: { templateKey: { eq: "testimony-page" } }
    ) {
      frontmatter {
        title
        heroImage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        intro
        overlay
      }
    }
    posts: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "testimony-post" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          sticky
          title
          headline
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
              fixed(width: 250, height: 250) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        id
        excerpt(pruneLength: 250)
        fields {
          slug
        }
      }
    }
  }
`;
